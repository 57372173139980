import { useCallback } from 'react';
import { useAxiosInstance } from '../lib';
import {
  GetAPI,
  PaginatedRequest,
  PaginatedResponse,
  PaginatedResponseDTO,
  TrainingArea
} from 'src/types';
import { TrainingAreaDTO } from 'src/types/dto/TrainingAreaDTO.type';
import {
  getTrainingAreaFacilityTypeFromDto,
  getDtoFromTrainingAreaFacilityType
} from './dto-mappers';

export interface ITrainingAreasService {
  getTrainingAreas: GetAPI<TrainingArea>;
  getTrainingArea: (
    id: number,
    abortController?: AbortController
  ) => Promise<TrainingArea>;
  deleteTrainingArea: (
    id: number,
    abortController?: AbortController
  ) => Promise<void>;
  createTrainingArea: (
    trainingArea: TrainingArea,
    abortController?: AbortController
  ) => Promise<void>;
  editTrainingArea: (
    id: number,
    trainingArea: TrainingArea,
    abortController?: AbortController
  ) => Promise<void>;
  findDistinctTrainingSites: (
    moduleScheduleId: number,
    abortController?: AbortController
  ) => Promise<TrainingArea[]>;
  getIsUserApproverForTrainingSite: (
    userId: number,
    trainingSiteId: number,
    abortController?: AbortController
  ) => Promise<boolean>;
}

export const useTrainingAreasService = (): ITrainingAreasService => {
  const trainingAreaApi = useAxiosInstance('/v2/trainingSites');

  const getTrainingAreas = useCallback(
    async (
      request: PaginatedRequest
    ): Promise<PaginatedResponse<TrainingArea>> => {
      const controller = request.abortController || new AbortController();

      return trainingAreaApi
        .get('', {
          params: request,
          withCredentials: true,
          signal: controller.signal
        })
        .then((response) => {
          const { content, first, last, number, totalElements, totalPages } =
            response.data as PaginatedResponseDTO<TrainingAreaDTO>;

          return {
            items: content.map((contentItem) =>
              getTrainingAreaFromDto(contentItem)
            ),
            hasNextPage: !last,
            hasPrevPage: !first,
            pageNumber: number,
            totalCount: totalElements,
            totalPages: totalPages
          } as PaginatedResponse<TrainingArea>;
        });
    },
    [trainingAreaApi]
  );

  const getTrainingArea = useCallback(
    (id: number, abortController?: AbortController): Promise<TrainingArea> =>
      trainingAreaApi
        .get(`/${id}`, {
          signal: abortController?.signal,
          withCredentials: true
        })
        .then((response) =>
          getTrainingAreaFromDto(response.data as TrainingAreaDTO)
        ),
    [trainingAreaApi]
  );

  const deleteTrainingArea = useCallback(
    (id: number, abortController?: AbortController): Promise<void> => {
      return trainingAreaApi.delete(`/${id}`, {
        withCredentials: true,
        signal: abortController ? abortController.signal : undefined
      });
    },
    [trainingAreaApi]
  );

  const createTrainingArea = useCallback(
    (
      trainingArea: TrainingArea,
      abortController?: AbortController
    ): Promise<void> =>
      trainingAreaApi.post('', getDtoFromTrainingArea(trainingArea), {
        signal: abortController?.signal,
        withCredentials: true
      }),
    [trainingAreaApi]
  );

  const editTrainingArea = useCallback(
    (
      id: number,
      trainingArea: TrainingArea,
      abortController?: AbortController
    ): Promise<void> =>
      trainingAreaApi.put(`${id}`, getDtoFromTrainingArea(trainingArea), {
        withCredentials: true,
        signal: abortController?.signal
      }),
    [trainingAreaApi]
  );

  const getIsUserApproverForTrainingSite = (
    userId: number,
    trainingSiteId: number,
    abortController?: AbortController
  ): Promise<boolean> => {
    return getTrainingArea(trainingSiteId, abortController).then(
      (trainingSite) =>
        !!trainingSite &&
        !!trainingSite.approverUserIds &&
        !!trainingSite.approverUserIds.find((value) => value === userId)
    );
  };

  const findDistinctTrainingSites = useCallback(
    (
      moduleScheduleId: number,
      abortController?: AbortController
    ): Promise<TrainingArea[]> => {
      return trainingAreaApi
        .get('/distinctTrainingSitesWithPlacements', {
          params: { moduleScheduleId: moduleScheduleId },
          withCredentials: true,
          signal: abortController?.signal
        })
        .then((response) => {
          const trainingAreaDtoList = response.data as TrainingAreaDTO[];
          return trainingAreaDtoList.map((trainingArea) =>
            getTrainingAreaFromDto(trainingArea)
          );
        });
    },
    [trainingAreaApi]
  );

  return {
    getTrainingAreas,
    getTrainingArea,
    deleteTrainingArea,
    createTrainingArea,
    editTrainingArea,
    findDistinctTrainingSites,
    getIsUserApproverForTrainingSite
  };
};

function getTrainingAreaFromDto(dto: TrainingAreaDTO): TrainingArea {
  const { facilityType } = dto;
  return {
    ...dto,
    facilityType: getTrainingAreaFacilityTypeFromDto(facilityType)
  };
}

function getDtoFromTrainingArea(trainingArea: TrainingArea): TrainingAreaDTO {
  const { facilityType } = trainingArea;
  return {
    ...trainingArea,
    facilityType: getDtoFromTrainingAreaFacilityType(facilityType)
  };
}
