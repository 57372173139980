import {
  Gender,
  PlacementScheduleDetails,
  PlacementScheduleStudentDetails,
  Recurrence,
  Weekday
} from 'src/types';
import {
  DATE_TIME_FORMAT,
  getDisplayDateStringFromDate
} from '../lib/date-utils';
import { useStudentGroupsService } from 'src/services/student-groups-service';
import { useClassListsService } from 'src/services/class-lists-service';
import { usePlacementSchedulesService } from 'src/services/placement-schedules-service';
import { useSupervisorsService } from 'src/services/supervisors-service';
import { useRoomBookingService } from 'src/services/room-booking-service';
import {
  RoomBooking,
  RoomBookingStatus
} from 'src/types/models/RoomBooking.type';

export interface IPlacementSchedulesDetailsService {
  getPlacementSchedulesDetails: (
    placementScheduleId: number
  ) => Promise<PlacementScheduleDetails>;
}

export const usePlacementSchedulesDetailsService =
  (): IPlacementSchedulesDetailsService => {
    const { getPlacementSchedule } = usePlacementSchedulesService();
    const { getStudentGroups } = useStudentGroupsService();
    const { getClassListStudents } = useClassListsService();
    const { getSupervisorsWithoutPagination } = useSupervisorsService();
    const { getRoomBooking } = useRoomBookingService();

    const getPlacementSchedulesDetails = (
      placementScheduleId: number
    ): Promise<PlacementScheduleDetails> => {
      return getPlacementSchedule(placementScheduleId)
        .then((placementSchedule) => {
          return Promise.all([
            Promise.resolve(placementSchedule),
            getStudentGroups(placementSchedule.moduleScheduleId),
            getClassListStudents(placementSchedule.moduleScheduleId),
            placementSchedule.supervisorIds
              ? getSupervisorsWithoutPagination(placementSchedule.supervisorIds)
              : Promise.resolve([]),
            placementSchedule.roomBookingId
              ? getRoomBooking(placementSchedule.roomBookingId)
              : Promise.resolve(undefined)
          ]);
        })
        .then(
          ([
            placementSchedule,
            studentGroups,
            students,
            supervisors,
            roomBooking
          ]) => {
            const studentGroup = studentGroups.find(
              (studentGroup) =>
                studentGroup.id === placementSchedule.studentGroupId
            );
            if (placementSchedule.studentGroupId && !studentGroup) {
              throw new Error(
                'student group id in placement schedule not found in list of class list groups'
              );
            }

            const studentsInGroup = studentGroup
              ? students
                  .filter(
                    (student) =>
                      studentGroup.studentIds.indexOf(student.id) >= 0
                  )
                  .map<PlacementScheduleStudentDetails>((student) => {
                    return {
                      id: student.id,
                      displayName: student.displayName,
                      hasOwnTransport: student.hasOwnTransport,
                      licenceExpiry: student.licenseExpiry,
                      gender: student.gender ?? '',
                      genderV2: student.genderV2 ?? Gender.NONE,
                      needTransport:
                        !placementSchedule.transportStudentsIds.includes(
                          student.id
                        ),
                      needAccommodation:
                        !placementSchedule.accommodationStudentsIds.includes(
                          student.id
                        ),
                      carRegistration:
                        (student.isAvailableForBooking &&
                          student.carRegistration) ||
                        'Not Available',
                      bookable:
                        student.hasOwnTransport && student.isAvailableForBooking
                    };
                  })
              : [];

            const {
              moduleName,
              themeName,
              activityType,
              startDateTime,
              endDateTime,
              recurrence,
              notes
            } = placementSchedule;

            return {
              moduleName: moduleName,
              moduleScheduleName: placementSchedule.moduleScheduleName,
              themeName: themeName,
              activityType: activityType,
              startDateTime: startDateTime,
              endDateTime: endDateTime,
              recurrenceDetails: getRecurrenceDetails(recurrence),
              trainingArea: roomBooking
                ? `${roomBooking.facilityName} - ${roomBooking.trainingSiteName}`
                : 'No training area booked',
              trainingAreaOverCapacity: roomBooking
                ? roomBooking.capacity - studentsInGroup.length
                : undefined,
              supervisors: supervisors.map(
                (supervisor) => supervisor.displayName
              ),
              notes: notes,
              academicBooking: getRoomBookingDetails(roomBooking),
              groupName: studentGroup?.name,
              groupStudents: studentsInGroup
            } as PlacementScheduleDetails;
          }
        );
    };

    function getWeekdayString(weekday: Weekday) {
      switch (weekday) {
        case Weekday.MONDAY:
          return 'Monday';
        case Weekday.TUESDAY:
          return 'Tuesday';
        case Weekday.WEDNESDAY:
          return 'Wednesday';
        case Weekday.THURSDAY:
          return 'Thursday';
        case Weekday.FRIDAY:
          return 'Friday';
        case Weekday.SATURDAY:
          return 'Saturday';
        case Weekday.SUNDAY:
          return 'Sunday';
      }
    }

    function getRecurrenceDetails(recurrence: Recurrence): string[] {
      const result: string[] = [
        `From ${getDisplayDateStringFromDate(
          recurrence.activeDate,
          DATE_TIME_FORMAT.TIME_FORMAT
        )} to ${getDisplayDateStringFromDate(
          recurrence.expiryDate,
          DATE_TIME_FORMAT.TIME_FORMAT
        )} ${
          recurrence.weekdays.length > 0
            ? `every ${recurrence.weekdays.map(getWeekdayString).join(', ')}`
            : ''
        }`
      ];

      return result;
    }

    function getRoomBookingDetails(roomBooking?: RoomBooking): string[] {
      if (roomBooking && !roomBooking.deletionReason) {
        const result = ['Has Academic Booking'];

        switch (roomBooking.status) {
          case RoomBookingStatus.AWAITING_APPROVAL:
            result.push('But has not yet been approved.');
            break;
          case RoomBookingStatus.REJECTED:
            result.push('But was rejected.');
            break;
          case RoomBookingStatus.APPROVED:
            if (roomBooking.approvalDateTime) {
              result.push(
                `Booking was approved on ${getDisplayDateStringFromDate(
                  roomBooking.approvalDateTime,
                  DATE_TIME_FORMAT.DEFAULT
                )}.`
              );
            } else {
              result.push('Booking was approved.');
            }
            break;
        }

        return result;
      } else {
        return ['No Booking'];
      }
    }

    return { getPlacementSchedulesDetails };
  };
