import { Reducer } from 'react';
import { DateRange, DateRangeType } from 'src/types';
import { addTimeToDate, getDateRangeFromDateAndDateRangeType } from 'src/lib';

export enum DateRangeControlActionType {
  GO_TO_NEXT_DATE_RANGE,
  GO_TO_PREVIOUS_DATE_RANGE,
  GO_TO_SPECIFIC_DATE_RANGE_CONTAINING_DATE,
  SET_DATE_RANGE_TYPE
}

export type NextDateRangeAction = {
  type: DateRangeControlActionType.GO_TO_NEXT_DATE_RANGE;
};

export type PreviousDateRangeAction = {
  type: DateRangeControlActionType.GO_TO_PREVIOUS_DATE_RANGE;
};

export type SpecificDateRangeContainingDate = {
  type: DateRangeControlActionType.GO_TO_SPECIFIC_DATE_RANGE_CONTAINING_DATE;
  payload: Date;
};

export type SetDateRangeTypeAction = {
  type: DateRangeControlActionType.SET_DATE_RANGE_TYPE;
  payload: DateRangeType;
};

export type DateRangeControlAction =
  | NextDateRangeAction
  | PreviousDateRangeAction
  | SetDateRangeTypeAction
  | SpecificDateRangeContainingDate;

export type DateRangeControlState = {
  dateRange: DateRange;
  dateRangeType: DateRangeType;
};

export const dateRangeControlReducer: Reducer<
  DateRangeControlState,
  DateRangeControlAction
> = (state: DateRangeControlState, action: DateRangeControlAction) => {
  const { type } = action;

  switch (type) {
    case DateRangeControlActionType.GO_TO_PREVIOUS_DATE_RANGE: {
      return {
        ...state,
        dateRange: {
          fromDate: addTimeToDate(
            state.dateRange.fromDate,
            -1,
            state.dateRangeType
          ),
          toDate: addTimeToDate(state.dateRange.toDate, -1, state.dateRangeType)
        }
      };
    }
    case DateRangeControlActionType.GO_TO_NEXT_DATE_RANGE: {
      return {
        ...state,
        dateRange: {
          fromDate: addTimeToDate(
            state.dateRange.fromDate,
            1,
            state.dateRangeType
          ),
          toDate: addTimeToDate(state.dateRange.toDate, 1, state.dateRangeType)
        }
      };
    }
    case DateRangeControlActionType.GO_TO_SPECIFIC_DATE_RANGE_CONTAINING_DATE: {
      const { payload } = action;
      const dateRange = getDateRangeFromDateAndDateRangeType(
        payload,
        state.dateRangeType
      );

      return {
        ...state,
        dateRange: dateRange
      };
    }
    case DateRangeControlActionType.SET_DATE_RANGE_TYPE: {
      const dateRange = getDateRangeFromDateAndDateRangeType(
        state.dateRange.fromDate,
        action.payload
      );

      return {
        ...state,
        dateRange: dateRange,
        dateRangeType: action.payload
      };
    }
    default: {
      return state;
    }
  }
};
